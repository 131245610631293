import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux-starter-kit';
import { withState } from 'recompose';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { PlaidLink } from 'react-plaid-link';
import { withStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Colors from '../theme/colors';
import PaperSheet from './PaperSheet';
import PLAID from '../api/plaid';

const styles = (theme) => ({
    plaidBtn: {
        borderRadius: 25,
        width: 250,
    },
    plaidLinkBtn: {
        backgroundColor: 'transparent',
        width: '100%',
        borderColor: 'transparent',
        color: 'white',
        fontSize: 14,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        color: Colors.secondary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});
const Bank = ({
    children,
    nextStep,
    submitToken,
    classes,
    bankNotFound,
    setBankNotFound,
    loading,
    error,
    bankNotFoundText = 'My bank is not listed.',
    allowSkip = false,
    factorCompany,
    showUploadAttachments,
    handleSubmit,
    btnTitle,
}) => {
    const [linkToken, setLinkToken] = useState(null);
    const [isFetching, setIsFetching] = useState(null);

    useEffect(() => {
        const fetchLinkToken = async () => {
            try {
                setIsFetching(true);
                const { link_token } = await PLAID.getLinkToken();
                setLinkToken(link_token);
            } catch (err) {
                console.error('Error fetching link token:', err);
            } finally {
                setIsFetching(false);
            }
        };
        !linkToken && fetchLinkToken();
    }, [setLinkToken, linkToken]);

    if (isFetching || loading) {
        return (
            <CircularProgress size={24} className={classes.buttonProgress} />
        );
    }

    return (
        <Grid
            container
            xs={10}
            sm={9}
            md={8}
            direction="column"
            justify="center"
            alignItems="stretch"
            alignContent="center"
            style={{ margin: 'auto', width: '100%' }}
            spacing={16}
        >
            <Grid item style={{ alignSelf: 'center' }}>
                <div className={classes.wrapper}>
                    {linkToken && (
                        <PlaidLink
                            style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                color: 'white',
                                fontSize: 14,
                            }}
                            env={process.env.REACT_APP_PLAID_ENV}
                            token={linkToken}
                            onExit={(err, metadata) => {
                                setBankNotFound(true);
                            }}
                            onSuccess={(token, { accounts }) => {
                                submitToken(
                                    token,
                                    accounts.map((account) => account.id),
                                );
                                handleSubmit && handleSubmit();
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                size="medium"
                                className={classes.plaidBtn}
                                disabled={loading}
                            >
                                Add your bank info
                            </Button>
                        </PlaidLink>
                    )}
                    {loading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>
            </Grid>
            {factorCompany ? (
                <Grid container justify="center" item style={{ width: '100%' }}>
                    {factorCompany}
                </Grid>
            ) : null}
            {error && (
                <Grid item xs={12}>
                    <PaperSheet
                        message="Error while adding bank info, please contact our support team."
                        variant="error"
                    />
                </Grid>
            )}
            {bankNotFound && (
                <>
                    {btnTitle && btnTitle}
                    <Grid
                        container
                        justify="center"
                        item
                        style={{ width: '100%' }}
                    >
                        <Button
                            onClick={showUploadAttachments || nextStep}
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={classes.plaidBtn}
                            style={{ paddingTop: 5 }}
                        >
                            {bankNotFoundText}
                        </Button>
                        <Grid
                            container
                            direction="column"
                            xs={12}
                            style={{
                                alignSelf: 'center',
                                minWidth: '100%',
                                margin: 5,
                                padding: 0,
                            }}
                        >
                            {children}
                        </Grid>
                    </Grid>
                </>
            )}
            {allowSkip && (
                <Grid item>
                    <Grid
                        item
                        xs={12}
                        alignItems="flex-end"
                        direction="row"
                        justify="flex-end"
                    >
                        <Typography
                            component="p"
                            align="center"
                            style={{
                                flexDirection: 'row',
                                color: Colors.danger,
                            }}
                        >
                            Please enter your bank information for us to
                            accelerate your application.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row-reverse">
                            <Button
                                onClick={nextStep}
                                color="primary"
                                size="medium"
                            >
                                Skip
                                <ChevronRight className={classes.rightIcon} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

Bank.propTypes = {
    nextStep: PropTypes.func.isRequired,
    submitToken: PropTypes.func.isRequired,
    bankNotFound: PropTypes.bool.isRequired,
    setBankNotFound: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
    withState('bankNotFound', 'setBankNotFound', false),
)(Bank);
